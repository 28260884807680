<!-- eslint-disable -->
<template>
  <div class='icon-check'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.033" height="15.556" viewBox="0 0 20.033 15.556">
      <path id="check" d="M7.671,15.662a1.022,1.022,0,0,1-1.446,0L.45,9.886a1.534,1.534,0,0,1,0-2.169l.723-.723a1.534,1.534,0,0,1,2.17,0L6.948,10.6,16.691.856a1.534,1.534,0,0,1,2.17,0l.723.723a1.534,1.534,0,0,1,0,2.169Zm0,0" transform="translate(0 -0.406)" :fill="color"/>
    </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconCheck',
  props: {
    color: {
      type: String,
      required: false,
      default: '#3D6EB4',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
