<template>
  <div class='base-checkbox'>
    <div class="input-wrapper" :class="[{ 'checked': isChecked }, { 'has-error': hasError }]">
      <input type="checkbox" v-model="localInputValue">
      <icon-check v-if="isChecked" :color="'#0eb1eb'" />
    </div>
    <span v-if="label" class="label" v-html="label" @click="handleLabelClick" />
  </div>
</template>

<script>
import IconCheck from '@/components/shared/elements/icons/IconCheck';

export default {
  name: 'BaseCheckbox',
  props: {
    isChecked: {
      type: Boolean,
      required: true,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    isLabelClickable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    IconCheck,
  },
  data: () => ({
    localInputValue: undefined,
  }),
  created() {
    this.localInputVal = this.isChecked;
  },
  computed: {},
  watch: {
    localInputValue(val) {
      this.$emit('changed', val);
    },
    isChecked(val) {
      this.localInputValue = val;
    },
  },
  methods: {
    handleLabelClick(e) {
      if (this.isLabelClickable && e.target.tagName !== 'A') {
        this.localInputValue = !this.localInputValue;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$width: 18px;
$height: 18px;
$checkIconWidth: 20px;
$checkIconHeight: 15px;

.base-checkbox {
  display: flex;
  align-items: center;
}

.input-wrapper {
  width: $width;
  height: $height;
  position: relative;
  border-radius: 0;
  background: $white;
  border: 2px solid $globalBorderColor;
  display: flex;

  @media screen and (max-width: $breakpointDownXs) {
    width: calc(#{$width} + 10px);
    height: calc(#{$height} + 10px);
  }

  &.has-error {
    border-color: $globalErrorColor;
  }
}

.icon-check {
  position: absolute;
  top: -4px;
  right: -7px;
  display: flex;
  z-index: 0;

  &::v-deep svg {
    width: $checkIconWidth;
    height: $checkIconHeight;

    @media screen and (max-width: $breakpointDownXs) {
      width: calc(#{$checkIconWidth} + 9px);
      height: calc(#{$checkIconHeight} + 9px);
    }
  }
}

input {
  appearance: none;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border: 0;

  &:focus {
    outline: none;
  }
}

.label {
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 0 5px;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: $breakpointDownXs) {
    margin: 0 0 0 16px;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
